import React, { useEffect, useState } from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const Upcoming: React.FC = () => {
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    console.log(currentDate)
    const mayFirst = new Date(currentDate.getFullYear(), 4, 1); // Months are 0-indexed, so 4 is May
    console.log(currentDate >= mayFirst)
    if (currentDate >= mayFirst) {
      setShouldHide(true);
    }
  }, []);
  const events = [
       {
      title: `Oral History Workshop`,
      location: 'UNO/CEC',
      date: 'July 11th 2024',
      image: './img/calendar/oralhistoryworkshop.png',
      link: 'https://tockify.com/the.house.events/detail/180/1720738800000',
      description: `More information coming soon!`
    },
    {
      title: `Young History Detectives`,
      location: 'UNO Barbara Weitz Community Enagement Center',
      date: 'July 22nd 2024',
      image: './img/events/yhd2024.png',
      link: 'https://form.jotform.com/230800915193149',
      description: `More information coming soon!`
    },
{
      title: `Nap Night`,
      location: 'UNO Barbara Weitz Community Enagement Center',
      date: 'July 28th 2024',
      image: './img/events/napnightjuly.png',
      link: 'https://www.eventbrite.com/e/929886865647?aff=oddtdtcreator',
      description: ``,
    },
{
      title: `Nap Night`,
      location: 'Metro',
      date: 'October 2024',
      image: './img/events/afrocon2024.png',
      link: 'https://www.afroconomaha.com',
      description: ``,
    },
    // {
    //     title:`NukeCon Board Game Con`,
    //     location:'Mid-America Center Council Bluffs',
    //     date:'Friday September 22nd - Sunday September 24th', 
    //     image:'./img/calendar/nukecon.jpg',
    //     link:'https://www.nuke-con.com/',
    //     description:`Nuke-Con is an all-volunteer organization dedicated to promoting the positive educational, social, and entertainment aspects in all types of gaming hobbies. We'll be a vendor there. More information coming soon!`
    // },
    // {
    //     title:`Divisible Documentary Film Screening`,
    //     location:'TBD',
    //     date:'TBD', 
    //     image:'./img/calendar/film.jpg',
    //     link:'https://www.divisibledoc.com/',
    //     description:` More information coming soon!`
    // },
    // {
    //     title:`AfroCon 2023: Making Space to Dream`,
    //     location:'TBD',
    //     date:'Saturday October 7th', 
    //     image:'./img/calendar/afrocon.jpg',
    //     link:'http://www.afroconomaha.com/',
    //     description:` More information coming soon!`
    // },
    // {
    //     title:`AfroCon VIP Brunch`,
    //     location:'TBD',
    //     date:'Sunday October 8th', 
    //     image:'./img/calendar/brunch.jpg',
    //     link:'http://www.afroconomaha.com/',
    //     description:` More information coming soon!`
    // },

  ]


  return (
    <section className="page-section bg-light" id="photos">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">
                Our Upcoming Events
              </h2>
          

              <div className="row justify-content-center">
                {events.map((event, idx) => (
                  <div className="col-xs-6 col-md-3" key={idx}>
                    <a href={event.link} className="thumbnail" target="_blank" rel="noreferrer">
                      <img src={event.image} className="img-fluid rounded-start event-img" alt="..." />
                    </a>
                    <div className="forms d-flex justify-content-around mt-2">
                      {/* {event?.vendorForm && !shouldHide ? <a href={event?.vendorForm} target="_blank" rel="noreferrer" className="btn btn-info">Vendor Form</a> :''}                                  
                                    
                                       {event.volunteerForm && !shouldHide ?<a href={event.volunteerForm} target="_blank" rel="noreferrer" className="btn btn-info">Volunteer Form</a>:''} */}
                    </div>
                  </div>
                  //     <div className="card mb-3" key={idx}>
                  //     <div className="row g-0">
                  //       <div className="col-md-5">
                  //         <img src={event.image} className="img-fluid rounded-start" alt="..." />
                  //       </div>
                  //       {/* <div className="col-md-7">
                  //         <div className="card-body">
                  //           <h5 className="card-title">{event.title} : {event.date} @ {event.location}</h5>
                  //           <p className="card-text">{event.description}</p>
                  //           <a href={event.link} rel="noreferrer" target="_blank" className="btn btn-danger mb-3 btn-sm">
                  //     Find out more!
                  // </a>
                  //         </div>
                  //       </div> */}
                  //     </div>
                  //   </div>

                ))

                }
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Upcoming
