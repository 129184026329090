import React from 'react'
import { useNavigate } from 'react-router-dom'
import TopNav from '../components/TopNav'
import { ROUTES } from '../resources/routes-constants'
import GmaBanner from '../components/GmaBanner'
import Footer from '../components/Footer'
const EducationPage: React.FC = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.INTRO_ROUTE)
    }

    return (
        <>
            <TopNav title="Education" />
            <div className="container-fluid w-100 h-auto m-0 p-0">
                <img src="./img/kids/yhds.jpeg" className="img-fluid w-100 pt-4 mt-5 top-image" alt="Patience" style={{ height: '50%' }} />
            </div>
            {/* <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h1 style={{ fontSize: '4em' }}>Education</h1>
            
            </div> */}

            <section className="" id="services" style={{ padding: 0 }}>
                <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div className="text-center" style={{ backgroundColor: '#908068', color: 'black' }}>
                                <h1 className="text-uppercase py-1 subheading">Education</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <h3 className="section-subheading text-muted">
                                    Businesses and fellow foundations have given their time and support to help us make a difference in children&apos;s lives.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                        </div>
                    </div>

                    <div className="row mt-5 d-flex justify-content-center">

                        <div className="col-12 col-md-6">
                            <div className="card mb-3" style={{ maxWidth: "100%" }}>
                                <div className="row g-0">
                                    <div className="col-md-6">
                                        <img src="./img/kids/yhdjr.png" className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h5 className="card-title">Junior Young History Detectives</h5>
                                            <p className="card-text" style={{ overflow: "visible" }}>  Junior Young History Detectives is open to 9-11 year olds, interested in learning Black History, Omaha History in fun and engaging ways. This year&apos;s camps will explore storytelling as a tool to present their discoveries of the people, places and events in North Omaha.
                                                Summer Junior Young History Detectives Program is <strong>Monday-Friday, July 22-26, 2024 10am-5pm, at the University of Nebraska at Omaha</strong>.
                                                <a className="btn btn-info" href="https://form.jotform.com/240456639969171">Junior Young History Detectives Interest Form 2024</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card mb-3" style={{ maxWidth: "100%" }}>
                                <div className="row g-0">
                                    <div className="col-md-6">
                                        <img src="./img/kids/yhd.png" className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h5 className="card-title">Young History Detectives</h5>
                                            <p className="card-text" style={{ overflow: "visible" }}>  Young History Detectives (YHD) is our youth enrichment program. It uses geek culture to promote literacy, critical thinking and digital literacy. The Young History Detectives learn Black History, both regional, national, and across the African Diaspora along with historical research methods to better understand US History. The YHD Summer Camp is <strong>Monday-Friday, July 29- August 9, 2024 10am-5pm, at the University of Nebraska at Omaha</strong>.
                                                <a className="btn btn-info" href="https://form.jotform.com/240456639969171">Young History Detectives Interest Form 2024</a>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="text-center mt-5">
                        <h2>Our Newsletter</h2>
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-6"><img src="./img/education/springnewsletter1.png" className="img-fluid" /></div>
                        <div className="col-12 col-md-6"><img src="./img/education/springnewsletter2.png" className="img-fluid" /></div>
                    </div>
                    <div className="text-center mt-5">
                        <h2>Upcoming Events</h2>
                    </div>
                    <div className="row text-muted">

                        <div className="col-12 col-md-6"><img src="./img/calendar/oralhistoryworkshop.png" className="img-fluid mt-2" /></div>
                        <div className="col-12 col-md-6"><h5 className='mt-3'>This summer YHD participants will conduct an oral history.</h5>
                        <h5 className='mt-3' style={{lineHeight:1.5}}> Oral Histories are a vital part of preserving the communities history. In a world where information is increasingly digitized and fleeting, oral histories offer a unique opportunity to capture the essence of human experiences and perspectives. </h5>
                        <h5 className='mt-3' style={{lineHeight:1.5}}>These narratives, passed down through generations, provide invaluable insights into cultures, traditions, and historical events that may otherwise be lost or distorted over time. This workshop will prepare our youth and their parents for this important work.</h5></div>
                    </div>
                    <div className="text-center mt-5">
                        <h2>Pictures from our Events</h2>
                    </div>

                    <div className="container mt-5">

                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                            {/* Image 1 */}
                            <div className="col">
                                <img src="./img/education/edimg1.jpeg" alt="Image 1" className="img-fluid" />
                            </div>
                            {/* Image 2 */}
                            <div className="col">
                                <img src="./img/education/edimg2.jpeg" alt="Image 2" className="img-fluid" />
                            </div>
                            {/* Image 3 */}
                            <div className="col">
                                <img src="./img/education/edimg3.jpeg" alt="Image 3" className="img-fluid" />
                            </div>
                            {/* Image 4 */}
                            <div className="col">
                                <img src="./img/education/edimg4.jpeg" alt="Image 4" className="img-fluid" />
                            </div>
                            {/* Image 5 */}
                            <div className="col">
                                <img src="./img/education/edimg5.jpeg" alt="Image 5" className="img-fluid" />
                            </div>
                            {/* Image 6 */}
                            <div className="col">
                                <img src="./img/education/edimg6.jpg" alt="Image 6" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <div className="row">
                <div className="col">
                    <GmaBanner />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default EducationPage
